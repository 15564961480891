<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        multiple?: boolean;
        withNullOption?: boolean;
        options?: FormSelectOption[];
    }
    import { FormInputData } from '~~/composables/useFormInput'
    const emit = defineEmits(['change']);
    interface FormSelectOption {
        value: string | number;
        text: string;
        disabled?: boolean;
    }
    
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  options: null
});
    const inputData: FormInputData = useFormInput(props);
</script>

<template>
    <FormElement :input-data="inputData">
        <select
            :id="inputData.inputId.value"
            v-model="inputData.model.value"
            :class="`${inputData.className.value} cursor-pointer`"
            :name="inputData.props.inputName"
            :multiple="multiple"
            @change="emit('change', $event)"
        >
            <option
                v-if="withNullOption"
                value=""
            >
                {{ $t('misc.select_placeholder') }}
            </option>
            <slot>
                <option
                    v-for="option in options"
                    :key="option.value"
                    :value="option.value"
                >
                    {{ option.text }}
                </option>
            </slot>
        </select>
    </FormElement>
</template>
